import React, { useEffect, useState } from "react";
import apiBaseURL from "../../../../utils/apiBaseURL";
import ax from "../../../../utils/ax";
import ExperimentCard from "../ExperimentCard/ExperimentCard";

const cardColorClasses = [
	"bg-p-blue",
	"bg-emerald-500",
	"bg-violet-800",
	"bg-pink-800"
];

const hiddenExperimentIDs = [4];

const listExperiments = (experiments) => {
	// bring experimentID 1 to the end of the array experiments (Add Device card)

	const exp0 = experiments.find((exp) => exp.experimentID === 0);
	if (exp0) {
		let orderedExperiments = experiments.filter(
			(exp) => exp.experimentID !== 0
		);
		orderedExperiments.push(exp0);
		experiments = orderedExperiments;
	}

	const exp1= experiments.find((exp) => exp.experimentID === 1);
	if (exp1) {
		let orderedExperiments = experiments.filter(
			(exp) => exp.experimentID !== 1
		);
		orderedExperiments.push(exp1);
		experiments = orderedExperiments;
	}

	const exp2= experiments.find((exp) => exp.experimentID === 2);
	if (exp2) {
		let orderedExperiments = experiments.filter(
			(exp) => exp.experimentID !== 2
		);
		orderedExperiments.push(exp2);
		experiments = orderedExperiments;
	}

	const exp3= experiments.find((exp) => exp.experimentID === 3);
	if (exp3) {
		let orderedExperiments = experiments.filter(
			(exp) => exp.experimentID !== 3
		);
		orderedExperiments.push(exp3);
		experiments = orderedExperiments;
	}

	const exp4= experiments.find((exp) => exp.experimentID === 4);
	if (exp4) {
		let orderedExperiments = experiments.filter(
			(exp) => exp.experimentID !== 4
		);
		orderedExperiments.push(exp4);
		experiments = orderedExperiments;
	}

	const exp5= experiments.find((exp) => exp.experimentID === 5);
	if (exp5) {
		let orderedExperiments = experiments.filter(
			(exp) => exp.experimentID !== 5
		);
		orderedExperiments.push(exp5);
		experiments = orderedExperiments;
	}
	
	const exp6= experiments.find((exp) => exp.experimentID === 6);
	if (exp5) {
		let orderedExperiments = experiments.filter(
			(exp) => exp.experimentID !== 6
		);
		orderedExperiments.push(exp6);
		experiments = orderedExperiments;
	}



	let experimentList = [];

	experiments.forEach((exp, index) => {
		if (hiddenExperimentIDs.indexOf(exp.experimentID) !== -1) return;

		experimentList.push(
			<div className="flex justify-center" key={index}>
				<ExperimentCard
					info={exp}
					className={cardColorClasses[index % cardColorClasses.length]}
				/>
			</div>
		);
	});

	return experimentList;
};

function ExperimentList() {
	const [experiments, setExperiments] = useState([]);

	useEffect(() => {
		ax.get(apiBaseURL + "/experiments")
			.then((res) => res.data)
			.then((data) => {
				setExperiments(data);
				console.log(data)
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	return (
		<div className="relative flex flex-cols-1 sm:flex-cols-2 md:flex-cols-2 lg:flex-cols-2">
			{listExperiments(experiments)}
		</div>
	);
}

export default ExperimentList;
